<template>
    <div class="wage-services legal-services company-formation">
        <div class="container">
            <div class="banner-static-services">
                <h4>
                    {{ $t('Welcome to the Wages Protection System service in the Kingdom of Saudi Arabia') }}
                </h4>
                <p>
                    {{ $t('Welcome to the Wages desc') }}
                </p>
            </div>
          <Contact
            :descriptions="['wage-contact-desc1', 'wage-contact-desc2', 'wage-contact-desc3']"
            :slogan="'wage-contact-slogan'"
          />
            <div class="features">
                <div class="title">
                    {{ $t('Advantages of the wage protection system with completion') }}
                </div>
                <p class="w8">
                   {{ $t('advantge wage desc') }}
                </p>
                <div class="row mt-5">
                    <div class="col-md-4 mb-3">
                        <div class="item">
                            <div class="title">{{ $t('Ensuring financial security') }}</div>
                            <p>{{ $t('Ensuring financial security desc') }}</p>
                        </div>
                    </div>
                    <div class="col-md-4 mb-3">
                        <div class="item">
                            <div class="title">{{ $t('Transparent mechanisms for estimation') }}</div>
                            <p>{{ $t('Transparent mechanisms for estimation desc') }}</p>
                        </div>
                    </div>
                    <div class="col-md-4 mb-3">
                        <div class="item">
                            <div class="title">{{ $t('Labor law') }}</div>
                            <p>{{ $t('We seek to improve wage standards by combating economic transformations and ensuring financial justice among all workers') }}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="banner-2">
                <div class="row">
                    <div class="col-md-6 mb-3">
                        <img class="w-100" src="@/assets/img/services-page/wage-banner-2.png" alt="">
                    </div>
                    <div class="col-md-6" style="display: grid;place-content: center;">
                        <div class="title">
                            {{ $t('Wage protection') }}
                            <div style="font-size: 16px;"> {{ $t('In the Kingdom of Saudi Arabia') }}</div>
                        </div>
                        <p>
                           {{ $t('Wage protection desc') }}
                        </p>
                    </div>
                </div>
            </div>
            <div class="how-help-you">
                <div class="title">
                    {{ $t('Wage Protection System Services with Etmaam') }}
                </div>
              <p>
                {{ $t('Comprehensive Solutions for Managing the Wage Protection System') }}
              </p>
              <div class="row mt-5 d-flex justify-content-center">
                <div class="col-md-2 flex-grow-1 d-flex">
                        <div class="item">
                            <div class="title">{{ $t('Coordination with Banks') }}</div>
                            <p class="multiline-text">
                                {{ $t('Open bank accounts for institutions and companies.') }}
                            </p>
                        </div>
                    </div>
                <div class="col-md-2 flex-grow-1 d-flex">
                        <div class="item">
                            <div class="title">{{ $t('Employee Account Management') }}</div>
                            <p class="multiline-text">
                               {{ $t('Open bank accounts for employees and request payroll cards.') }}
                            </p>
                        </div>
                    </div>
                <div class="col-md-2 flex-grow-1 d-flex">
                        <div class="item">
                            <div class="title">{{ $t('Uploading Salary Files') }}</div>
                            <p class="multiline-text">
                                {{ $t('Upload files to bank systems and the Ministry of Human Resources, automatically detecting errors.') }}
                            </p>
                        </div>
                    </div>
                <div class="col-md-2 flex-grow-1 d-flex">
                        <div class="item">
                            <div class="title">{{ $t('Auditing and Inspection') }}</div>
                            <p class="multiline-text">
                                {{ $t('Audit salary files to avoid violations and compare data with the General Organization for Social Insurance.') }}
                            </p>
                        </div>
                    </div>
                <div class="col-md-2 flex-grow-1 d-flex">
                    <div class="item">
                      <div class="title">{{ $t('Support and Training') }}</div>
                      <p class="multiline-text">
                        {{ $t('Train clients on using the system and provide ongoing support to ensure efficient operations.') }}
                      </p>
                    </div>
                  </div>
                </div>
            </div>
            <div class="steps-termination">
                <div class="row">
                    <div class="col-md-4 mb-3 info" >
                        <h3>{{ $t('procedures') }} </h3>
                        <h2>{{ $t('Wage protection') }}</h2>
                    </div>
                    <div class="col-md-8">
                        <div class="row">
                            <div class="col-md-6 mb-3">
                                <div class="item d-flex align-items-center">
                                    <img src="@/assets/img/services-page/termination-5.svg" alt="">
                                    <div>
                                        <h4>{{ $t('wage-protection-procedures1') }}</h4>
                                        <p>{{ $t('wage-protection-procedures-desc1') }}</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6 mb-3 d-flex align-items-center">
                                <div class="item d-flex align-items-center">
                                    <img src="@/assets/img/services-page/termination-2.svg" alt="">
                                    <div>
                                        <h4>{{ $t('wage-protection-procedures2') }}</h4>
                                        <p>{{ $t('wage-protection-procedures-desc2') }}</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6 mb-3 d-flex align-items-center">
                                <div class="item d-flex align-items-center">
                                    <img src="@/assets/img/services-page/termination-6.svg" alt="">
                                    <div>
                                        <h4>{{ $t('wage-protection-procedures3') }}</h4>
                                        <p>{{ $t('wage-protection-procedures-desc3') }}</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6 mb-3 d-flex align-items-center">
                                <div class="item d-flex align-items-center">
                                    <img src="@/assets/img/services-page/termination-7.svg" alt="">
                                    <div>
                                        <h4>{{ $t('wage-protection-procedures4') }}</h4>
                                        <p>{{ $t('wage-protection-procedures-desc4') }}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import { defineAsyncComponent } from 'vue'

export default {
    components: {
        Contact: defineAsyncComponent(() => import('./component/contact-services.vue')),
    },
    data() {
        return {
          title:'',
      description:'',
      img:'',
      card_1_title:'',
      card_1_description:'',
      card_1_photo:'',
      card_2_title:'',
      card_2_description:'',
      card_2_photo:'',
      card_3_title:'',
      card_3_description:'',
      card_3_photo:'',
        };
    },
    methods: {
      handleStaticAbout(StaticContent){
    StaticContent.forEach((item) => {
    if (item.key == 'about_etmaam') {
      this.title = item.value;
    }
    if(item.key == 'about_etmaam_description'){
      this.description = item.value;
    }
    if(item.key == 'about_etmaam_photo'){
      this.img = item.value;
    }
    if(item.key == 'card_1_title'){
      this.card_1_title = item.value;
    }
    if(item.key == 'card_1_description'){
      this.card_1_description = item.value;
    }
    if(item.key == 'card_1_photo'){
      this.card_1_photo = item.value;
    }
    if(item.key == 'card_2_title'){
      this.card_2_title = item.value;
    }
    if(item.key == 'card_2_description'){
      this.card_2_description = item.value;
    }
    if(item.key == 'card_2_photo'){
      this.card_2_photo = item.value;
    }
    if(item.key == 'card_3_title'){
      this.card_3_title = item.value;
    }
    if(item.key == 'card_3_description'){
      this.card_3_description = item.value;
    }
    if(item.key == 'card_3_photo'){
      this.card_3_photo = item.value;
    }
  });
    },
    },
    mounted() {
      this.$store.dispatch('GetStaticContent').then(r => {
          this.handleStaticAbout(this.$store.state.StaticContent);
    });
    }

}
</script>
